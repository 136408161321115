import http from '../lib/http'
import judgStatus from './base'
// const RESTURL = '/back/addPoster'
// const GETURL = '/back/getPoster'
const ONEURL = '/back/getConfigInfo'
const EDITURL = '/back/editConfig'
// const DELURL = '/back/delPoster'
const API = {
    create(data) {
        return new Promise((resolve, reject) => {
            http.post(RESTURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    update(data) {
        return new Promise((resolve, reject) => {
            http.post(EDITURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    del(data) {
        return new Promise((resolve, reject) => {
            http.post(DELURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    query() {
        return new Promise((resolve, reject) => {
            http.post(GETURL).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
    one(data) {
        return new Promise((resolve, reject) => {
            http.post(ONEURL, data).then(res => {
                const scs = judgStatus(res)
                resolve(scs)
            }).catch(error => {
                reject(error)
            })
        })
    },
}
export default API
