<template>
  <div class="xcx">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <div class="img">
          <van-uploader
            v-model="adList"
            :max-count="1"
            :after-read="beforeRead"
          />
        </div>
        <van-field
          v-model="state.page_path"
          name="page_path"
          label="小程序路径"
          placeholder="小程序路径"
          :rules="[{ required: true, message: '请填写小程序路径' }]"
        />
        <van-field
          v-model="state.appid"
          name="appid"
          label="小程序appid"
          placeholder="小程序appid"
          :rules="[{ required: true, message: '请填写小程序appid' }]"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
        <van-button
          round
          block
          type="default"
          style="margin-top: 20px"
          @click="cencal"
        >
          取消更改
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script lang="ts">
import { reactive, ref } from "vue";
import { Button, CellGroup, Field, Form, Uploader, Toast, Dialog } from "vant";
import httpAdvert from "../../../api/advertInfo";
import httpUploadFile from "../../../api/uploadFile";
export default {
  components: {
    "van-form": Form,
    "van-cell-group": CellGroup,
    "van-uploader": Uploader,
    "van-field": Field,
    "van-button": Button,
  },
  name: "AdvertInfo",
  setup() {
    // 上传广告图
    const adList = ref([]);
    // 上传海报
    const beforeRead = async (file) => {
      if (!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.file.name)) {
        adList.value = [];
        return Toast("请上传图片");
      }
      const files = new FormData();
      files.append("file", file.file);
      const res = await httpUploadFile.uploadFile(files);
      if (res) {
        adList.value = [{ url: "https://water.mfyyxm.com" + res.data }];
        state.img_src = res.data;
      }
    };

    // 小程序
    const state = reactive({
      id: 1,
      page_path: "",
      appid: "",
      img_src: "",
    });
    const oldState = reactive({
      page_path: "",
      appid: "",
      img_src: "",
    });
    // 获取广告
    const adobj = ref({});
    const getAdv = async () => {
      const res = await httpAdvert.one(state);
      if (res) {
        adobj.value = res.data;
        state.page_path = res.data.page_path;
        state.appid = res.data.appid;
        state.img_src = res.data.img_src;
        oldState.page_path = res.data.page_path;
        oldState.appid = res.data.appid;
        oldState.img_src = res.data.img_src;
        adList.value = [{ url: "https://water.mfyyxm.com" + res.data.img_src }];
        // console.log(adobj);
      }
    };
    getAdv();
    // 提交
    const onSubmit = () => {
      // console.log("submit", state);
      Dialog.confirm({
        title: "更改提示",
        confirmButtonText: "是",
        cancelButtonText: "否",
        message: "是否更改该数据，提交后不可取消",
      })
        .then(async () => {
          // on confirm
          const res = await httpAdvert.update(state);
          if (res) {
            Toast(res.msg);
            oldState.page_path = "";
            oldState.appid = "";
            oldState.img_src = "";
            getAdv();
          }
        })
        .catch(() => {
          // on cancel
          // console.log("取消");
        });
    };
    const cencal = () => {
      if (oldState.appid == "") {
        return Toast("已提交的更改不可取消");
      }
      if (
        !(
          oldState.appid == state.appid &&
          oldState.img_src == state.img_src &&
          oldState.page_path == state.page_path
        )
      ) {
        getAdv();
        return;
      }
      return Toast("没有更改");
    };
    return {
      adList,
      state,
      adobj,
      onSubmit,
      cencal,
      beforeRead,
    };
  },
};
</script>
<style scoped lang="less"></style>
