<template>
  <div class="setting-box">
    <van-tabs v-model:active="active">
      <van-tab title="广告图">
        <!-- <div class="xcx">
          <van-form @submit="onSubmit">
            <van-cell-group inset>
              <div class="img">
                <van-uploader v-model="adList" multiple :max-count="1" />
              </div>
              <van-field
                v-model="state.name"
                name="小程序路径"
                label="小程序路径"
                placeholder="小程序路径"
                :rules="[{ required: true, message: '请填写小程序路径' }]"
              />
              <van-field
                v-model="state.appid"
                name="小程序appid"
                label="小程序appid"
                placeholder="小程序appid"
                :rules="[{ required: true, message: '请填写小程序appid' }]"
              />
            </van-cell-group>
            <div style="margin: 16px">
              <van-button round block type="primary" native-type="submit">
                提交
              </van-button>
            </div>
          </van-form>
        </div> -->
        <AdvertInfo></AdvertInfo>
      </van-tab>
      <van-tab title="海报图">
        <Poster></Poster>
      </van-tab>
      <van-tab title="品牌管理">
        <brandSettings></brandSettings>
      </van-tab>
      <van-tab title="其他配置">
        <OtherConfig></OtherConfig>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import { Tab, Tabs, Button, Uploader, Form, Field, CellGroup } from "vant";
import room from "./roomManagement.vue";
import Poster from "./poster.vue";
import OtherConfig from "./otherConfig.vue";
import AdvertInfo from "./advertInfo.vue";
import brandSettings from "./brandSettings.vue"
export default {
  name: "SystemSettings",
  components: {
    "van-tabs": Tabs,
    "van-tab": Tab,
    "van-button": Button,
    "van-uploader": Uploader,
    "van-form": Form,
    "van-field": Field,
    "van-cell-group": CellGroup,
    room,
    Poster,
    AdvertInfo,
    OtherConfig,
    brandSettings,
  },
  setup() {
    const active = ref(0);
    // 上传广告图
    const adList = ref([]);
    // 小程序
    const state = reactive({
      name: "",
      appid: "",
    });
    const onSubmit = (values) => {
      // console.log("submit", values);
    };
    // 海报
    const posterList = ref([]);
    const submitImg = () => {
      // console.log(posterList.value);
    };
    return { active, adList, state, posterList, onSubmit, submitImg };
  },
};
</script>
<style scoped lang="less">
::v-deep .van-tabs__wrap {
  margin-bottom: 20px;
}
::v-deep .van-image {
  border: 2px solid skyblue;
}
.area {
  outline: none;
  resize: none;
  padding: 20px;
}
.fb-btn {
  margin-top: 30px;
  border-radius: 10px;
}
.img-box {
  padding: 10px;
  box-sizing: border-box;
  min-height: 600px;
}
.sub-img-btn {
  margin: 32px;
  margin-top: 200px;
}
</style>
