<template>
    <div class="other-box">
        <div class="top-list">
            <van-button type="primary" style="margin-bottom: 10px;margin-left: 15px;" @click="addCampus">添加品牌</van-button>
        </div>
        <van-tabs @click="clickCampus" type="card" >
            <van-tab :title="item.campus_name" v-for="(item,index) in campusArray" :key="item" />
        </van-tabs>
        <div class="pinpai-list-box">
            <div class="pinpai-list-item" v-for="(item,index) in pinpaiList" :key="item">
                <div class="pinpai-list-item-left">
                    <div class="pinpai-list-item-left-p">
                        <p class="p-left">校区：</p>
                        <p class="p-right">{{item.campus_name}}</p>
                    </div>
                    <div class="pinpai-list-item-left-p">
                        <p class="p-left">品牌：</p>
                        <p class="p-right">{{item.brand_name}}</p>
                    </div>
                    <div class="pinpai-list-item-left-p">
                        <p class="p-left">价格：</p>
                        <p class="p-right">{{item.brand_price}}元</p>
                    </div>
                    <div class="pinpai-list-item-left-p">
                        <p class="p-left">排序：</p>
                        <p class="p-right">{{item.sort_num}}</p>
                    </div>
                    <div class="pinpai-list-item-left-p">
                        <p class="p-left">上限桶数：</p>
                        <p class="p-right">{{item.brand_upper_limit}}桶</p>
                    </div>
                    <div class="pinpai-list-item-left-p" style="border-bottom: 0px;">
                        <p class="p-left">可送区域：</p>
                        <p class="p-right"> <span v-for="(aa,index) in item.building_text" :key="aa">{{aa.name}}{{index == item.building_text.length-1 ? '' : ','}}</span> </p>
                    </div>
                </div>
                <div class="pinpai-list-item-right">
                    <van-button type="primary" size="small" style="margin-bottom: 10px;margin-top: 20px;" @click="editShow(item)">编辑</van-button>
                    <van-button type="danger" size="small" @click="delBrandFun(item)">删除</van-button>
                </div>
            </div>
            <div v-if="pinpaiList.length == 0">
                <p>~暂无品牌~</p>
            </div>
        </div>
        <!-- 弹窗 -->
        <van-dialog
        v-model:show="show"
        :title="isEdit ? '编辑品牌' : '新增品牌'"
        :show-confirm-button="false"
        >
            <van-cell-group>
                <div class="user-status">
                    <span class="span-status">校区：</span>
                    <van-popover
                    v-model:show="showRolePopover"
                    :actions="campusArray"
                    @select="onSelectCampus"
                    >
                    <template #reference>
                        <van-button type="default" size="mini">
                            {{ addEditData.campus_name ? addEditData.campus_name: "请选择校区" }}
                        </van-button>
                    </template>
                    </van-popover>
                </div>
                <van-field v-model="addEditData.brand_name" label="品牌：" placeholder="填写品牌名称" />
                <van-field v-model="addEditData.brand_price" label="价格：" type="number" placeholder="填写价格" />
                <van-field v-model="addEditData.brand_upper_limit" label="上限桶数：" type="number" placeholder="填写价格" />
                <van-field v-model="addEditData.sort_num" label="排序：" type="number" placeholder="数字越小排的越前面" />
                <van-field name="checkboxGroup" label="可送区域：">
                    <template #input v-if="addEditData.campus_name" >
                        <van-checkbox-group v-model="addEditData.building_no" style="width:cala(100% - 137px);height:200px;overflow-y: scroll;">
                            <van-checkbox style="margin-bottom:10px;" :name="item.id" shape="square" v-for="item in dongshuList" :key="item.id">{{item.name}}</van-checkbox>
                        </van-checkbox-group>
                    </template>
                    <template #input v-if="!addEditData.campus_name" >
                        <span style="color:#999;">请先选择校区</span>
                    </template>
                </van-field>
            </van-cell-group>
            <div class="select-bottom-box">
                <div class="select-bottom-left" @click="show = false">取消</div>
                <div class="select-bottom-right" @click="addEditBrandFun">提交</div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import httpCampus from "../../../api/getCampus";
import bfr from "../../../api/room";
import { setBuildText,setCengText,setRoomText } from '../../../lib/setBuild'

import {
  Image,
  Cell,
  CellGroup,
  Button,
  Icon,
  Dialog,
  Uploader,
  Toast,
  Field,
  Tab, Tabs ,
  Popover,
  Popup ,
  Picker ,
  CheckboxGroup,
  Checkbox,
} from "vant";
export default{
    name: "brandSettings",
    components: {
        "van-image": Image,
        "van-cell": Cell,
        "van-button": Button,
        "van-icon": Icon,
        "van-uploader": Uploader,
        "van-dialog": Dialog.Component,
        "van-field": Field,
        "van-tab":Tab,
        "van-tabs":Tabs,
        "van-popup":Popup ,
        "van-popover": Popover,
        "van-picker":Picker,
        "van-checkbox":Checkbox,
        "van-checkbox-group":CheckboxGroup,
        "van-cell-group":CellGroup,
    },
    setup(){
        const show = ref(false)
        const isEdit = ref(false)
        const showPicker = ref(false)
        const showRolePopover = ref(false)
        const selectCam = ref(0)
        const campusArray = ref([]) //校区列表
        const pinpaiList = ref([]) //品牌列表
        const dongshuList = ref([])//栋数列表
        const addEditData = ref({
            campus_id:'',
            campus_name:'',
            brand_id:'',
            brand_name:'',
            brand_price:'',
            brand_upper_limit:'',
            sort_num:'',
            building_no:[]
        })

        //获取校区列表
        const getCampusFun = async ()=>{
            const res = await httpCampus.getCampusList();
            if(res){
                res.data.forEach((el) => {
                    el.text = el.campus_name;
                });
                campusArray.value = res.data
                getBrandList(res.data[0].campus_id)
            }
        }
        getCampusFun()
        //标题点击校区
        const clickCampus = (index) => {
            let id = campusArray.value[index].campus_id
            selectCam.value = index
            getBrandList(id)
        }
        //品牌列表
        const getBrandList = async (id)=>{
            Toast.loading({
                message: '加载中...',
                forbidClick: true
            })
            const res = await httpCampus.getBrands({
                campus_id:id
            })
            if(res){
                let data = res.data 
                data.forEach((item,index)=>{
                    if(item.building_no == ''){
                        item.building_text = []
                    }else{
                        item.building_text = setBuildText(item.campus_name,item.building_no.split(','))
                    }
                })
                pinpaiList.value = ''
                pinpaiList.value = data
                Toast.clear()
            }
        }
        //选择校区
        const onSelectCampus = (e)=>{
            addEditData.value.campus_id = e.campus_id
            addEditData.value.campus_name = e.campus_name
            addEditData.value.building_no = []
            getBuildingList()
        }

        //获取栋数
        const getBuildingList = async () => {
            Toast.loading({
                message: '加载中...',
                forbidClick: true
            })
            try {
                const res = await bfr.getAllBuilding({
                campus_id:addEditData.value.campus_id
                });
                if (res.code == 200) {
                    Toast.clear()
                    dongshuList.value = setBuildText(addEditData.value.campus_name,res.data)
                }
            } catch (error) {
                Toast("获取栋数出错");
            }
        };

        //点击新增
        const addCampus = ()=>{
            addEditData.value = {
                campus_id:'',
                campus_name:'',
                brand_id:'',
                brand_name:'',
                brand_price:'',
                brand_upper_limit:'',
                sort_num:'',
                building_no:[],
            }
            show.value = true
            isEdit.value = false
        }
        // 点击编辑
        const editShow = (item)=>{
            if(!Array.isArray(item.building_no) && item.building_no !== ""){
                item.building_no = item.building_no.split(',').map(Number)
            }else{
                item.building_no = []
            }
            addEditData.value = item
            show.value = true
            isEdit.value = true
            getBuildingList()
        }
        //点击删除  
        const delBrandFun = (item)=>{
            Dialog.confirm({
                title: "删除提示",
                message: "你确定要删除这品牌吗",
            }).then(() => {
                const res = httpCampus.delBrand({ ids: item.brand_id });
                if (res) {
                    Toast(res.msg)
                    getBrandList(campusArray.value[selectCam.value].campus_id)
                }
            }).catch(() => {
            // console.log("取消");
            });
        }
        //新增编辑品牌
        const addEditBrandFun = async (e)=>{
            if(addEditData.value.building_no == ''){
                    Toast('请选择可送区域')
                return false
            }
            if(addEditData.value.brand_id == ''){
                //新增
                const res = await httpCampus.addBrand({
                    brand_name:addEditData.value.brand_name,
                    campus_id:addEditData.value.campus_id,
                    brand_price:addEditData.value.brand_price,
                    brand_upper_limit:addEditData.value.brand_upper_limit,
                    sort_num:addEditData.value.sort_num,
                    building_no:addEditData.value.building_no.join(','),
                })
                if(res){
                    console.log(res)
                    show.value = false
                    Toast(res.msg)
                    getBrandList(campusArray.value[selectCam.value].campus_id)
                }
            }else{
                //编辑
                const res = await httpCampus.editBrand({
                    brand_name:addEditData.value.brand_name,
                    campus_id:addEditData.value.campus_id,
                    brand_price:addEditData.value.brand_price,
                    brand_upper_limit:addEditData.value.brand_upper_limit,
                    sort_num:addEditData.value.sort_num,
                    brand_id:addEditData.value.brand_id,
                    building_no:addEditData.value.building_no.join(','),
                })
                if(res){
                    console.log(res)
                    show.value = false
                    Toast(res.msg)
                    getBrandList(campusArray.value[selectCam.value].campus_id)
                }
            }
        }

        return{
            campusArray,
            pinpaiList,
            show,
            isEdit,
            addEditData,
            showPicker,
            showRolePopover,
            dongshuList,

            clickCampus,
            onSelectCampus,
            addEditBrandFun,
            addCampus,
            editShow,
            delBrandFun,
        }
    }
}
</script>

<style scoped lang="less">
    .top-list{
        text-align: left;
    }
    .pinpai-list-box{
        width: 100%;
        box-sizing: border-box;
        padding: 0 30px;
    }
    .pinpai-list-item{
        width: 100%;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #999;
        margin-bottom: 30px;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 15px;
    }
    .pinpai-list-item-left{
        width: calc(100% - 170px);
    }
    .pinpai-list-item-right{
        width: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .pinpai-list-item-left-p{
        width: 100%;
        min-height: 50px;
        line-height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #f2f2f2;
    }
    .pinpai-list-item-left-p p{
        line-height: 50px;
        margin: 0;
        padding: 0;
    }
    .p-left{
        width: 200px;
    }
    .p-right{
        width: calc(100% - 200px);
    }
    .select-bottom-box{
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 100px;
    }
    .select-bottom-left{
        width: 50%;
        height: 100%;
        line-height: 100px;
        border-bottom-left-radius: 30px;
    }
    .select-bottom-right{
        width: 50%;
        height: 100%;
        line-height: 100px;
        border-bottom-right-radius: 30px;
        color: #fff;
        background: var(--van-blue);
    }
    .user-status {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 28px;
        margin-left: 30px;
        padding: 20px 0;
        color: #646566;
    }
    .span-status {
        margin-right: 150px;
        font-size: 40px;
    }
    .gt-span {
        margin-left: 10px;
        display: inline-block;
        transform: rotate(90deg);
    }
    .other-box {
        padding-bottom: 120px;
    }
</style>