<template>
  <div class="poster-box">
    <van-button type="primary" @click="show = true"> 新增 </van-button>
    <van-cell
      class="first"
      v-for="item in posterList"
      :name="item.old_img_src"
      :key="item.id"
    >
      <!-- 使用 title 插槽来自定义标题 -->
      <template #title>
        <div
          style="display: flex; align-items: center; justify-content: space-between"
        >
          <van-image
            class="show-image"
            lazy-load
            fit="cover"
            :src="item.old_img_src"
          />
          <div style="display: flex; flex-direction: column">
            <van-button
              size="small"
              type="primary"
              style="margin-bottom: 10px"
              @click="editPoster(item)"
            >
              编辑
            </van-button>
            <van-button size="small" type="danger" @click="delPoster(item)">
              删除
            </van-button>
          </div>
        </div>
      </template>
    </van-cell>
    <!-- from -->
    <van-dialog
      v-model:show="show"
      :title="isEdit ? '编辑海报' : '新增海报'"
      :show-confirm-button="false"
    >
      <!-- 排序 -->
      <div class="order-box">
        <van-field
          v-model="params.order"
          label="排序"
          placeholder="请输入排序（选填）默认 0"
        />
      </div>
      <div class="img-box">
        海报图
        <van-uploader
          :after-read="beforeRead"
          v-model="imgSrc"
          :max-count="maxImgCount"
          style="margin-left: 50px"
        />
      </div>
      <div class="sub-img-btn" style="display: flex">
        <van-button
          round
          block
          type="default"
          @click="show = false"
          style="margin-right: 10px"
        >
          取消
        </van-button>
        <van-button round block type="primary" @click="submitImg">
          提交
        </van-button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
import httpPoster from "../../../api/poster";
import httpUploadFile from "../../../api/uploadFile";
import {
  Image,
  Cell,
  Button,
  Icon,
  Dialog,
  Uploader,
  Toast,
  Field,
} from "vant";
export default {
  name: "Poster",
  components: {
    "van-image": Image,
    "van-cell": Cell,
    "van-button": Button,
    "van-icon": Icon,
    "van-uploader": Uploader,
    "van-dialog": Dialog.Component,
    "van-field": Field,
  },
  setup() {
    const activeName = ref();
    const show = ref(false);
    // 获取海报列表
    const posterList = ref([]);
    const getPosterList = async () => {
      const res = await httpPoster.query();
      if (res) {
        res.data.forEach((el) => {
          el.old_img_src = "https://water.mfyyxm.com" + el.img_src;
        });
        posterList.value = res.data;
      }
    };
    getPosterList();
    //
    // 参数
    const params = reactive({
      img_src: "",
      order: 0,
    });
    const imgSrc = ref([]);

    // 编辑海报
    const isEdit = ref(false);
    const editPoster = async (item) => {
      isEdit.value = true;
      show.value = true;
      params.id = item.id;
      params.img_src = item.img_src;
      params.order = item.order;
      imgSrc.value = [{ url: item.old_img_src }];
    };

    // 删除海报
    const delPoster = (item) => {
      Dialog.confirm({
        title: "删除提示",
        message: "你确定要删除这张海报吗",
      })
        .then(async () => {
          const res = await httpPoster.del({ ids: [item.id] });
          if (res) {
            // console.log(res);
            Toast(res.msg);
            getPosterList();
          }
        })
        .catch(() => {
          // console.log("取消");
        });
    };

    // 点击提交
    const submitImg = async () => {
      if (isEdit.value) {
        const res = await httpPoster.update(params);
        if (res) {
          Toast("修改成功");
          show.value = false;
        }
      } else {
        const res = await httpPoster.create(params);
        if (res) {
          Toast("修改成功");
          show.value = false;
        }
      }
      getPosterList();
    };

    // 上传海报
    const beforeRead = async (file) => {
      if (!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.file.name)) {
        imgSrc.value = [];
        return Toast("请上传图片");
      }
      const files = new FormData();
      files.append("file", file.file);
      const res = await httpUploadFile.uploadFile(files);
      if (res) {
        // console.log(res);
        params.img_src = res.data;
      }
    };

    watch(show, (val) => {
      if (!val) {
        isEdit.value = false;
        params.img_src = "";
        params.order = 0;
        imgSrc.value = [];
      }
    });
    return {
      activeName,
      posterList,
      show,
      maxImgCount: 1,
      imgSrc,
      params,
      isEdit,

      editPoster,
      submitImg,
      beforeRead,
      delPoster,
    };
  },
};
</script>
<style scoped lang="less">
.poster-box {
  text-align: left;
}
.first {
  border-bottom: 1px solid #565656;
}
.img-box {
  display: flex;
  padding-left: 30px;
  font-size: 28px;
  color: #646566;
}
.show-image {
  width: 500px;
  height: 200px;
}
::v-deep .van-popup {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
</style>
