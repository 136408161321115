<template>
  <div class="admin-box">
    <div class="admin-body">
      <div class="admin-body-header">房间管理</div>
      <div class="table-box" style="text-align: left">
        <van-button
          type="primary"
          size="mini"
          style="margin-bottom: 10px"
          @click="show = true"
          >新增房间</van-button
        >
        <table class="fl-table">
          <thead>
            <tr>
              <th>房间号</th>
              <th>层号</th>
              <th>幢号</th>
              <th>楼房</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in roomList" :key="item.id">
              <td>{{ item.room_no }}</td>
              <td>{{ item.floor_no }}</td>
              <td>{{ item.building_no }}</td>
              <td>{{ item.name }}</td>
              <td style="display: flex;flex-direction: column;">
                <van-button type="default" size="small" @click="editRoom(item)"
                  >编辑</van-button
                >
                <van-button
                  type="danger"
                  size="small"
                  style="margin-top: 10px;"
                  @click="delRoom(item.id)"
                  >删除</van-button
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="pagination-box d-flex align-center"
          style="margin-top: 10px; padding-right: 20px; justify-content: flex-end"
        >
          <div class="pagination">
            <van-pagination
              v-model="currentPage"
              :page-count="pageCount"
              :show-page-size="3"
              force-ellipses
              @change="changePage"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="edit-user-data">
      <van-dialog v-model:show="show" title="新建房间" :showConfirmButton="false">
        <van-form @submit="onSubmit">
          <van-cell-group inset>
            <van-field
              v-model="state.name"
              name="name"
              label="楼层名称"
              placeholder="请输入楼层名称"
              :rules="[{ required: true, message: '请输入楼层名称' }]"
            />
            <van-field
              v-model="state.room_no"
              name="room_no"
              label="房间号"
              placeholder="请输入房间号"
              :rules="[{ required: true, message: '请输入房间号' }]"
            />
            <van-field
              v-model="state.floor_no"
              name="floor_no"
              label="楼层号"
              placeholder="请输入楼层号"
              :rules="[{ required: true, message: '请输入楼层号' }]"
            />
            <van-field
              v-model="state.building_no"
              name="building_no"
              label="幢号"
              placeholder="请输入幢号"
              :rules="[{ required: true, message: '请输入幢号' }]"
            />
            <van-popover
              v-model:show="showOrderPopover"
              :actions="orderActions"
              @select="onSelectOrder"
            >
              <template #reference>
                <van-field
                  v-model="state.order"
                  name="order"
                  label="排序"
                  disabled
                  placeholder="请输入排序（选填）"
                />
              </template>
            </van-popover>
          </van-cell-group>
          <div style="margin: 16px; display: flex">
            <van-button
              round
              block
              type="default"
              style="margin-right: 10px"
              @click="show = false"
            >
              取消
            </van-button>
            <van-button round block type="primary" native-type="submit">
              提交
            </van-button>
          </div>
        </van-form>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import httpRoom from "../../../api/room";
import {
  Pagination,
  Button,
  Form,
  Popup,
  CellGroup,
  Field,
  Popover,
  Dialog,
  Toast,
} from "vant";
export default {
  components: {
    "van-pagination": Pagination,
    "van-button": Button,
    "van-popup": Popup,
    "van-form": Form,
    "van-cell-group": CellGroup,
    "van-field": Field,
    "van-popover": Popover,
    "van-dialog": Dialog.Component,
  },
  name: "RoomManagement",
  setup() {
    const currentPage = ref(1);
    const pageCount = ref(1);
    const show = ref(false);
    const state = reactive({
      name: "",
      building_no: "",
      floor_no: "",
      room_no: "",
      order: 0,
    });
    // 新增房间号
    // 提交新建、编辑
    const isEdit = ref(false);
    const onSubmit = async (values) => {
      values.order = Number(values.order);
      if (!isEdit.value) {
        const res = await httpRoom.create(values);
        if (res.code == 200) {
          Toast(res.msg);
          getRoomList();
        }
      } else {
        const res = await httpRoom.update(state);
        if (res.code == 200) {
          Toast(res.msg);
          getRoomList();
        }
      }
      show.value = false;
    };
    // limit page
    const queryParams = reactive({
      name: "",
      building_no: "",
      floor_no: "",
      limit: 10,
      page: 1,
    });
    // 获取房间号
    const roomList = ref([]);
    const getRoomList = async () => {
      const res = await httpRoom.query(queryParams);
      if (res.code == 200) {
        roomList.value = res.data.list;
        pageCount.value = res.data.pageCount;
      }
    };
    getRoomList();

    // 修改房间号
    const editRoom = (item) => {
      isEdit.value = true;
      state.id = item.id;
      state.name = item.name;
      state.floor_no = item.floor_no;
      state.room_no = item.room_no;
      state.building_no = item.building_no;
      state.order = Number(item.order);
      show.value = true;
    };

    // 删除房间号
    const delRoom = (id) => {
      Dialog.confirm({
        title: "删除",
        message: "是否删除该房间",
      })
        .then(async () => {
          const res = await httpRoom.del({ ids: [id] });
          if (res.code == 200) {
            Toast(res.msg);
            getRoomList();
          }
        })
        .catch(() => {
          Toast("取消");
        });
    };

    // 选择排序
    const orderActions = ref([{ text: 0 }, { text: 1 }]);
    const showOrderPopover = ref(false);
    const onSelectOrder = (item) => {
      state.order = item.text;
    };

    // 分页
    const changePage = (page) => {
      queryParams.page = page;
      getRoomList();
    };

    // 监听弹窗关闭
    watch(show, (val) => {
      if (!val) {
        isEdit.value = false;
        state.id = "";
        state.name = "";
        state.floor_no = "";
        state.room_no = "";
        state.building_no = "";
        state.order = 0;
      }
    });
    return {
      roomList,
      showOrderPopover,
      show,
      orderActions,
      state,
      currentPage,
      pageCount,

      editRoom,
      onSubmit,
      delRoom,
      onSelectOrder,
      changePage,
    };
  },
};
</script>
<style scoped lang="less">
.tabbar-box {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  z-index: 999;
}
.table-box {
  //   margin: 0 20px;
  box-sizing: border-box;
}
.fl-table {
  border-radius: 10px;
  font-size: 24px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
  thead {
    th {
      color: #ffffff;
      background: #4fc3a1;
    }
    th:nth-child(odd) {
      color: #ffffff;
      background: #324960;
    }
  }
  td {
    border-right: 1px solid #f8f8f8;
    font-size: 12px;
  }
  td,
  th {
    text-align: center;
    padding: 8px;
    font-size: 36px;
  }
  tr:nth-child(even) {
    background: #f8f8f8;
  }
}
.fl-table > tbody > tr,
.fl-table > thead {
  display: table;
  width: 100%;
  table-layout: fixed; /* 重要  表格固定算法 */
}
.fl-table > tbody {
  height: 800px;
  overflow: hidden;
  display: block;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}
tr {
  height: 80px;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th {
  display: table-cell;
  vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
}
.admin-body-header {
  margin: 40px;
  font-weight: 800;
  font-size: 40px;
}
.user-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 28px;
  margin-left: 60px;
  padding: 20px 0;
  color: #646566;
}
.gt-span {
  margin-left: 10px;
  display: inline-block;
  transform: rotate(90deg);
}
.submit-btn {
  margin-top: 90px;
  margin-right: 20px;
  margin-left: 20px;
}
.span-status {
  margin-right: 60px;
}
::v-deep .pagination .van-pagination__item {
  height: 64px !important;
}
</style>
