<template>
  <div class="other-box">
    <van-cell-group inset style="font-size: 20px;">
      <van-field
        v-model="site_name"
        label="网站名称"
        placeholder="请输入用户名"
      />

      <!-- <van-field
        v-model="nf_name"
        label="产品1名称"
        placeholder="请输入产品1名称"
      />
      <van-field
        v-model="nf_price"
        label="产品1单价"
        placeholder="请输入产品1单价"
      />
      <van-field
        v-model="nf_num"
        label="产品1上限桶数"
        placeholder="请输入产品1上限桶数"
      />
      
      <van-field
        v-model="jt_name"
        label="产品2名称"
        placeholder="请输入产品2名称"
      />
      <van-field
        v-model="jt_price"
        label="产品2单价"
        placeholder="请输入产品2单价"
      />
      <van-field
        v-model="jt_num"
        label="产品2上限桶数"
        placeholder="请输入产品2上限桶数"
      /> -->

      <div
        style="text-align: left; padding: 10px 16px; padding-left: 16px; font-size: 20px"
      >
        LOGO图片
      </div>
      <div class="img">
        <van-uploader
          v-model="adList"
          :max-count="1"
          :after-read="beforeRead"
        />
      </div>
      <div style="padding: 30px;">
        <van-button round block type="primary" @click="submit"> 提交 </van-button>
      </div>
      <van-tabs @click="clickCampus" type="card" style="margin-top: 30px;">
          <van-tab style="margin: 0;"  :title="item.campus_name+'公告'" v-for="(item,index) in campusArray" :key="item" />
      </van-tabs>
        <van-field
          style="border: 1px solid #999;"
          type="textarea"
          v-model="message2"
          placeholder="请输入该校区公告"
          rows="8"
          show-word-limit
        />
        <div style="padding: 30px;">
          <van-button round block type="primary" @click="submitNotice"> 保存当前公告 </van-button>
        </div>
    </van-cell-group>
    
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";
import { Button, CellGroup, Field, Toast, Uploader,
  Tab, Tabs , } from "vant";
import httpUploadFile from "../../../api/uploadFile";
import httpConfig from "../../../api/system-config";
import httpCampus from "../../../api/getCampus";

export default {
  name: "OtherConfig",
  components: {
    "van-cell-group": CellGroup,
    "van-field": Field,
    "van-uploader": Uploader,
    "van-button": Button,
    "van-tab":Tab,
    "van-tabs":Tabs,
  },
  setup() {
    const data = reactive({
      site_name: "",
      logo_src: "",
      nf_price: "",
      nf_name:"",
      nf_num:"",
      jt_price: "",
      jt_name:"",
      jt_num:"",
    });
    // 上传logo图
    const adList = ref([]);
    // 上传logo
    const beforeRead = async (file) => {
      if (!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.file.name)) {
        adList.value = [];
        return Toast("请上传图片");
      }
      const files = new FormData();
      files.append("file", file.file);
      const res = await httpUploadFile.uploadFile(files);
      if (res) {
        adList.value = [{ url: "https://water.mfyyxm.com" + res.data }];
        data.logo_src = res.data;
      }
    };
    // 获取配置信息
    const getConfig = async () => {
      try {
        const res = await httpConfig.one({ id: 1 });
        if (res) {
          // console.log(res);
          data.id = res.data.id;
          data.site_name = res.data.site_name;
          data.logo_src = res.data.logo_src;
          data.nf_price = res.data.nf_price;
          data.nf_name = res.data.nf_name;
          data.nf_num = res.data.nf_num;
          data.jt_price = res.data.jt_price;
          data.jt_name = res.data.jt_name;
          data.jt_num = res.data.jt_num;

          adList.value = [
            { url: "https://water.mfyyxm.com" + res.data.logo_src },
          ];
        }
      } catch (error) {
        Toast("获取配置信息出错");
        // console.log(error);
      }
    };
    getConfig();

    const campusArray = ref([])//校区列表
    const selectCampus_id = ref(1)//选中的校区 默认清远校区1
    //获取校区列表
    const getCampusFun = async ()=>{
        const res = await httpCampus.getCampusList();
        if(res){
            res.data.forEach((el) => {
                el.text = el.campus_name;
            });
            campusArray.value = res.data
            getCampusDetailFun(res.data[0].campus_id)
        }
    }
    getCampusFun()
    //标题点击校区
    const clickCampus = (index) => {
      selectCampus_id.value = campusArray.value[index].campus_id
      getCampusDetailFun(selectCampus_id.value)
    }
    //获取校区详情
    const message2 = ref('')
    const getCampusDetailFun =async (id)=>{
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      const res = await httpCampus.getCampusDetail({
        id:id
      });
        if(res){
          message2.value = res.data.content
          Toast.clear()
        }
    }

    // 提交
    const submit = async () => {
      try {
        const res = await httpConfig.update(data);
        if (!res) return;
        if (res.code == 200) {
          getConfig();
          Toast("修改成功");
        }
      } catch (error) {
        // console.log(error);
        Toast("修改时出错了");
      }
    };
    //提交公告
    const submitNotice = async () =>{
      try {
        const res = await httpCampus.postAddContent({
          campus_id:selectCampus_id.value,
          content:message2.value
        });
        console.log(res)
        if (!res) return;
        if (res.code == 200) {
          Toast("提交成功");
        }
      } catch (error) {
        // console.log(error);
        Toast("修改时出错了");
      }
    };

    return {
      adList,
      beforeRead,
      submit,
      campusArray,
      message2,
      selectCampus_id,

      clickCampus,
      submitNotice,
      ...toRefs(data),
    };
  },
};
</script>
<style scoped lang="less">

::v-deep .van-tab{
  font-size: 16px;
}
.area {
  font-size: 40px;
  outline: none;
  resize: none;
  padding: 20px;
  max-width: 564px;
}
.other-box {
  padding-bottom: 120px;
}
::v-deep .van-button,
::v-deep .van-cell {
  font-size: 40px;
}
.notice-title-box{
  width: 100%;
  display: flex;
  flex-direction: row;
}
</style>
