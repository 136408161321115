import http from "../lib/http";
import judgStatus from "./base";
const RESTURL = "/back/uploadImg";
const API = {
  uploadFile(data) {
    // console.log(data);
    return new Promise((resolve, reject) => {
      http
        .post(RESTURL, data)
        .then((res) => {
          const scs = judgStatus(res);
          resolve(scs);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default API;
